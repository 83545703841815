<template>
  <div>
    <b-row class="match-height">
      <b-col md="12">
        <b-card title="恢复数据">
          <el-form
              ref="form"
              label-width="80px"
              :model="form"
          >
            <el-form-item
                label="上传文件"
                prop="fileName"
                :rules="[ { required: true, message: '文件不能为空', trigger: 'change' }]"
            >
              <input
                  id="uploadFileInput"
                  type="file"
                  style="display: none"
                  @change="uploadFileInputChange"
              >
              <el-input
                  v-model="form.fileName"
                  disabled
                  placeholder="请上传文件"
                  class="upload-file-input"
              >
                <template slot="append">
                  <div
                      style="cursor: pointer;"
                      @click="uploadFileClick"
                  >
                    选择文件
                  </div>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="text-center">
            <el-button
                type="primary"
                @click="submitForm"
            >
              提 交
            </el-button>
          </div>

        </b-card>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import { error, success } from '@/@core/utils/utils'
import { SysRestore } from '@/api/system/system'

export default {
  data() {
    return {
      form: {
        fileName: ''
      },
    }
  },
  created() {
  },
  methods: {
    uploadFileClick() {
      document.getElementById('uploadFileInput')
          .click()
    },
    uploadFileInputChange() {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      this.form.fileName = fileVal[0].name
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const uploadDom = document.getElementById('uploadFileInput')
          const formData = new FormData()
          formData.append('file', uploadDom.files[0])
          SysRestore(formData)
              .then(res => {
                this.loading = false
                const resData = res.data
                if (resData.code === 0) {
                  success(resData.msg)
                } else {
                  error(resData.msg)
                }
              })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.f20 {
  font-size: 20px;
}

.f18 {
  font-size: 18px;
}
</style>
